import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { ILocalStorage, ListType, ItemType, replaceAll } from '../objects';
import axios from 'axios';
import { saveState, loadState } from '../localStorage';
import {
    ItemsRequestAction, ItemsReceiveAction, ListsRequestAction, ListsReceiveAction, ItemOnEditAction, LoginAction, LogoutAction, ListsSetGUIDAction, ItemOnChangeAction, ShowHideListAction, EditListAction, ListSaveAction
    , Image64Action
} from '../Actions';

//npm install dotenv --save

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface TodoState {
    loginuser: string;
    image: string,
    email: string,
    lists: ListType[];

    selectedList: ListType;

    //listguid: string;
    //listname: string;
    //listlinked: boolean;
    data: ItemType[];

    //current: ItemType;


    editList: boolean;

    itemsIsLoading: boolean;
    listIsLoading: boolean;

    remindersLoaded: boolean;
    listLoaded: boolean;

    showLists: boolean;


}


// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.




function generateUUID() { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if (d > 0) {//Use timestamp until depleted
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}



function Save(email: string, listguid: string, data: ItemType, dispatch: any) {
    if (data != null) {

        var isSave = false;

        if (data.title != null && data.title.length > 0) isSave = true;
        if (data.description != null && data.description.length > 0) isSave = true;

        if (isSave) {

            const realcron = (data.cron != null ? replaceAll(data.cron, '\\?','1') : "");

            const IsCronValid = (realcron != null && realcron.length >= 9 && !(realcron.lastIndexOf("?") > 0)); console.log("IsCronValid", realcron, IsCronValid)


            var bodyFormData = new FormData();
            bodyFormData.append('guid', listguid);
            bodyFormData.append('id', data.id.toString());
            bodyFormData.append('title', data.title);
            bodyFormData.append('description', data.description);
            bodyFormData.append('priority', data.priority.toString());
            bodyFormData.append('objectDate', data.objectDate);
            bodyFormData.append('email', (IsCronValid ? email.toString() : ""));
            bodyFormData.append('cron', (IsCronValid ? realcron.toString() : ""));

            axios
                .post<ItemType[]>(process.env.REACT_APP_API_ENDPOINT + '/todoapi/api.php?action=addUpdateItem',
                    bodyFormData,
                    { headers: { "Content-Type": "multipart/form-data" } },
                )
                //.then(response => response.json() as Promise<FormObject[]>)
                .then(data => {
                    //console.log("c3", data, appState.reminders.listguid, guid, (data.data!=null && data.data.length>0 && data.data[0]!=null && data.data[0].guid != null && data.data[0].guid.length>0));
                    //dispatch({ type: 'ITEMS_RECEIVE', result: data.data });

                    console.log("saveList 2");
                    dispatch({ type: 'ITEMS_RECEIVE', result: data.data, setEdit: false });
                });
        }
        else {
            //delete if empty
            if (data.id > 0) {
                axios
                    .get<ItemType[]>(process.env.REACT_APP_API_ENDPOINT + '/todoapi/api.php?action=deleteItem'
                        + "&guid=" + listguid
                        + "&id=" + data.id
                    )
                    .then(data => {
                        //console.log("c1", data);
                        dispatch({ type: 'ITEMS_RECEIVE', result: data.data, setEdit: false });
                    });
            }


        }

    }
}


// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownFormsAction = ItemsRequestAction | ItemsReceiveAction | ListsRequestAction | ListsReceiveAction | ItemOnEditAction | LoginAction | LogoutAction | ListsSetGUIDAction | ItemOnChangeAction | ShowHideListAction | EditListAction | ListSaveAction
    | Image64Action
    ;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).


export const actionCreators = {


    login: (id: string, image: string, email: string): AppThunkAction<KnownFormsAction> => (dispatch, getState) => {

        console.log("login", id);
        const appState = getState();

        const state: ILocalStorage = loadState();

        console.log("image", image);

        if (image != null && image.length > 0) {
            axios({
                method: "GET",
                url: image,
                responseType: 'arraybuffer'
            })
                .then(response => {

                    console.log("image2", response);

                    var image64 = 'data:image/jpeg;base64,' + Buffer.from(response.data, 'binary').toString('base64');

                    console.log("image3", image64);

                    if (image64 != null && image64.length > 0) {

                        dispatch({ type: 'IMAGE64', image: image64 });
                    }
                }
                )
        }


        const tosave: ILocalStorage = {
            user: id,
            image: image,
            email: email,
            listguid: (state != null ? state.listguid : ''),
            listname: (state != null ? state.listname : '')

        }

        console.log("tosave: 1", tosave);
        saveState(
            tosave
        );

        dispatch({ type: 'LOGIN_USER', loginuser: id, email: email });

        dispatch({ type: 'LISTS_SET_GUID', guid: (state != null ? state.listguid : ''), name: (state != null ? state.listname : ''), linked: false });

        //LoadReminders(dispatch, appState);


    },

    logout: (): AppThunkAction<KnownFormsAction> => (dispatch, getState) => {

        console.log("logout");
        const appState = getState();

        const tosave: ILocalStorage = {
            user: '',
            image: '',
            email: '',
            listguid: '',
            listname: ''
        }
        console.log("tosave: 2", tosave);
        saveState(
            tosave
        );

        dispatch({ type: 'LOGOUT_USER' });


    },

    requestItems: (guid: string): AppThunkAction<KnownFormsAction> => (dispatch, getState) => {

        const appState = getState();

        console.log("requestItems", appState);

        if (appState && appState.reminders
            && appState.reminders.loginuser != null && appState.reminders.loginuser.length > 0
            && appState.reminders.selectedList.guid != null && appState.reminders.selectedList.guid.length > 0
            && appState.reminders.itemsIsLoading === false
            && appState.reminders.remindersLoaded === false
        ) {
            console.log("LoadItems");
            dispatch({ type: 'ITEMS_REQUEST' });
            //console.log("Results axios", appState.reminders.loginuser);
            axios
                .get<ItemType[]>(process.env.REACT_APP_API_ENDPOINT + '/todoapi/api.php?action=getItems&user=' + appState.reminders.loginuser + '&guid=' + appState.reminders.selectedList.guid)
                //.then(response => response.json() as Promise<FormObject[]>)
                .then(data => {
                    //console.log("c1", data);
                    dispatch({ type: 'ITEMS_RECEIVE', result: data.data, setEdit: false });
                });

        }

    },

    requestLists: (): AppThunkAction<KnownFormsAction> => (dispatch, getState) => {

        const appState = getState();

        console.log("requestLists()", appState);
        // Only load data if it's something we don't already have (and are not already loading)

        if (appState && appState.reminders
            && appState.reminders.loginuser != null && appState.reminders.loginuser.length > 0
            && appState.reminders.listLoaded === false
            && appState.reminders.listIsLoading === false
        ) {
            console.log("LoadLists");

            dispatch({ type: 'LISTS_REQUEST' });
            //console.log("Results axios", appState.reminders.loginuser);
            axios
                .get<ListType[]>(process.env.REACT_APP_API_ENDPOINT + '/todoapi/api.php?action=getLists&user=' + appState.reminders.loginuser)
                //.then(response => response.json() as Promise<FormObject[]>)
                .then(data => {
                    //console.log("c3", data);
                    dispatch({ type: 'LISTS_RECEIVE', result: data.data });

                    if (appState.reminders.selectedList.guid != null && appState.reminders.selectedList.guid.length > 0) {
                        //all OK
                        console.log("requestList OK");

                    }
                    else {
                        if (data.data != null && data.data.length > 0 && data.data[0] != null && data.data[0].guid != null && data.data[0].guid.length > 0) {
                            console.log("requestList set first ???");
                            dispatch({ type: 'LISTS_SET_GUID', guid: data.data[0].guid, name: data.data[0].name, linked: data.data[0].linked });
                        }

                    }
                });

        }
    }

    , onItemAdd: (priority: number): AppThunkAction<KnownFormsAction> => (dispatch, getState) => {
        const appState = getState();

        console.log("addItem()");

        const curentReminder: ItemType = appState.reminders.data.filter(x => x.isEdit == true)[0];
        /*
                axios
                    .get<ItemType[]>(process.env.REACT_APP_API_ENDPOINT + '/todoapi/api.php?action=addUpdateItem&guid=' + appState.reminders.selectedList.guid
                        + '&id=' + 0
                        + '&title=' + ""
                        + '&description=' + ""
                        + '&priority=' + priority
                        + '&objectDate=' + new Date().toISOString().slice(0, 19).replace('T', ' ')
                        + '&email=' + (null)
                        + '&cron=' + ("")
                    )
                    //.then(response => response.json() as Promise<FormObject[]>)
                    .then(data => {
        
                        console.log("saveList 2");
                        dispatch({ type: 'ITEMS_RECEIVE', result: data.data, setEdit: true });
                    });
        
        */

        var bodyFormData = new FormData();
        bodyFormData.append('guid', appState.reminders.selectedList.guid);
        bodyFormData.append('id', "0");
        bodyFormData.append('title', "");
        bodyFormData.append('description', "");
        bodyFormData.append('priority', priority.toString());
        bodyFormData.append('objectDate', new Date().toISOString().slice(0, 19).replace('T', ' '));
        bodyFormData.append('email', "");
        bodyFormData.append('cron', "");

        axios
            .post<ItemType[]>(process.env.REACT_APP_API_ENDPOINT + '/todoapi/api.php?action=addUpdateItem',
                bodyFormData,
                { headers: { "Content-Type": "multipart/form-data" } },
            )
            //.then(response => response.json() as Promise<FormObject[]>)
            .then(data => {
                //console.log("c3", data, appState.reminders.listguid, guid, (data.data!=null && data.data.length>0 && data.data[0]!=null && data.data[0].guid != null && data.data[0].guid.length>0));
                //dispatch({ type: 'ITEMS_RECEIVE', result: data.data });

                console.log("saveList 2");
                dispatch({ type: 'ITEMS_RECEIVE', result: data.data, setEdit: true });
            });

    }


    , onItemDelete: (id: number): AppThunkAction<KnownFormsAction> => (dispatch, getState) => {
        const appState = getState();

        axios
            .get<ItemType[]>(process.env.REACT_APP_API_ENDPOINT + '/todoapi/api.php?action=deleteItem&user=' + appState.reminders.loginuser
                + "&guid=" + appState.reminders.selectedList.guid
                + "&id=" + id
            )
            .then(data => {
                //console.log("c1", data);
                dispatch({ type: 'ITEMS_RECEIVE', result: data.data, setEdit: false });
            });



    }

    , onSetPriority: (id: number, priority: number): AppThunkAction<KnownFormsAction> => (dispatch, getState) => {
        const appState = getState();

        console.log("setOrder()", appState.reminders, priority, appState.reminders.loginuser);


        const curentReminder: ItemType = appState.reminders.data.filter(x => x.isEdit == true)[0];

        /*
        axios
            .get<ItemType[]>(process.env.REACT_APP_API_ENDPOINT + '/todoapi/api.php?action=addUpdateItem&guid=' + appState.reminders.selectedList.guid
                + '&id=' + id
                + '&title=' + curentReminder.title
                + '&description=' + curentReminder.description
                + '&priority=' + priority
                + '&objectDate=' + new Date().toISOString().slice(0, 19).replace('T', ' ')
            )
            //.then(response => response.json() as Promise<FormObject[]>)
            .then(data => {
                //console.log("c3", data, appState.reminders.listguid, guid, (data.data!=null && data.data.length>0 && data.data[0]!=null && data.data[0].guid != null && data.data[0].guid.length>0));
                //dispatch({ type: 'ITEMS_RECEIVE', result: data.data });

                console.log("saveList 2");
                dispatch({ type: 'ITEMS_RECEIVE', result: data.data, setEdit: false });
            });
*/
        const IsCronValid = (
            curentReminder.email != null && curentReminder.email.length >= 9 &&
            curentReminder.cron != null && curentReminder.cron.length >= 9 &&
            !(curentReminder.cron.lastIndexOf("?") > 0)); console.log("IsCronValid", curentReminder.cron, IsCronValid)


        var bodyFormData = new FormData();
        bodyFormData.append('guid', appState.reminders.selectedList.guid);
        bodyFormData.append('id', id.toString());
        bodyFormData.append('title', curentReminder.title);
        bodyFormData.append('description', curentReminder.description);
        bodyFormData.append('priority', priority.toString());
        bodyFormData.append('objectDate', new Date().toISOString().slice(0, 19).replace('T', ' '));
        bodyFormData.append('email', (IsCronValid ? curentReminder.email.toString() : ""));
        bodyFormData.append('cron', (IsCronValid && curentReminder.cron != null ? curentReminder.cron.toString() : ""));

        axios
            .post<ItemType[]>(process.env.REACT_APP_API_ENDPOINT + '/todoapi/api.php?action=addUpdateItem',
                bodyFormData,
                { headers: { "Content-Type": "multipart/form-data" } },
            )
            //.then(response => response.json() as Promise<FormObject[]>)
            .then(data => {
                //console.log("c3", data, appState.reminders.listguid, guid, (data.data!=null && data.data.length>0 && data.data[0]!=null && data.data[0].guid != null && data.data[0].guid.length>0));
                //dispatch({ type: 'ITEMS_RECEIVE', result: data.data });

                console.log("saveList 2");
                dispatch({ type: 'ITEMS_RECEIVE', result: data.data, setEdit: false });
            });


    },

    onItemEdit: (id: number): AppThunkAction<KnownFormsAction> => (dispatch, getState) => {

        const appState = getState();

        //save all edits

        const isEdited = appState.reminders.data.filter(x => x.isEdit == true);

        if (isEdited != null && isEdited.length > 0) {

            isEdited.forEach(element => {

                //save one
                Save(appState.reminders.email, appState.reminders.selectedList.guid, element, dispatch);

            });

        }


        dispatch({ type: 'ITEM_ON_EDIT', id: id });

    },


    //save values as we type
    onItemChange: (id: number, name: string, value: string): AppThunkAction<KnownFormsAction> => (dispatch, getState) => {

        const appState = getState();
        dispatch({ type: 'ITEM_ON_CHANGE', id: id, name: name, value: value });
    },

    //save values to db
    onItemSave: (id: number): AppThunkAction<KnownFormsAction> => (dispatch, getState) => {

        const appState = getState();

        const data = appState.reminders.data.filter(x => x.isEdit == true && x.id == id)[0];

        console.log('onChangeFinal data', data);

        Save(appState.reminders.email, appState.reminders.selectedList.guid, data, dispatch);

    },


    onListSelect: (guid: string, name: string): AppThunkAction<KnownFormsAction> => (dispatch, getState) => {

        console.log("setGUID: ", guid);

        const appState = getState();

        const listExists = appState.reminders.lists.filter(x => x.guid == guid);

        console.log("listExists", listExists);

        if (!listExists) {

            guid = "";
            name = "";

        }
        const tosave: ILocalStorage = {
            user: appState.reminders.loginuser,
            image: appState.reminders.image,
            email: appState.reminders.email,
            listguid: guid,
            listname: name
        }

        console.log("tosave: 3", tosave);
        saveState(
            tosave
        );

        dispatch({ type: 'LISTS_SET_GUID', guid: guid, name: name, linked: false });

    },
    onListAdd: (name: string): AppThunkAction<KnownFormsAction> => (dispatch, getState) => {


        console.log("addList: ", name);

        const appState = getState();
        /*
                const data: ReminderType[] = [];
        
                const body: RemindersType = {
                    data: [],
                    guid: newGUID,
                    name: name,
                    linked: false
                }
        */
        const guid = generateUUID();
        ;
        dispatch({ type: 'LISTS_REQUEST' });
        //console.log("Results axios", appState.reminders.loginuser);
        axios
            .get<ListType[]>(process.env.REACT_APP_API_ENDPOINT + '/todoapi/api.php?action=addUpdateList&user=' + appState.reminders.loginuser
                + '&guid=' + guid
                + '&name=' + name)
            //.then(response => response.json() as Promise<FormObject[]>)
            .then(data => {
                //console.log("c3", data, appState.reminders.listguid, guid, (data.data!=null && data.data.length>0 && data.data[0]!=null && data.data[0].guid != null && data.data[0].guid.length>0));
                dispatch({ type: 'LISTS_RECEIVE', result: data.data });

                if (appState.reminders.selectedList.guid == guid && data.data != null && data.data.length > 0 && data.data[0] != null && data.data[0].guid != null && data.data[0].guid.length > 0) {
                    //console.log("LISTS_SET_GUID");
                    dispatch({ type: 'LISTS_SET_GUID', guid: data.data[0].guid, name: data.data[0].name, linked: data.data[0].linked });
                }
            });




        //dispatch({ type: 'ADD_LIST', name: name, guid: newGUID });
    },
    onListDelete: (guid: string): AppThunkAction<KnownFormsAction> => (dispatch, getState) => {



        const appState = getState();

        console.log("removeList()", appState);
        // Only load data if it's something we don't already have (and are not already loading)

        if (appState && appState.reminders && appState.reminders.listIsLoading === false) {

            //console.log("requestList() fetch");

            dispatch({ type: 'LISTS_REQUEST' });
            //console.log("Results axios", appState.reminders.loginuser);
            axios
                .get<ListType[]>(process.env.REACT_APP_API_ENDPOINT + '/todoapi/api.php?action=deleteList&user=' + appState.reminders.loginuser + '&guid=' + guid)
                //.then(response => response.json() as Promise<FormObject[]>)
                .then(data => {
                    //console.log("c3", data, appState.reminders.listguid, guid, (data.data!=null && data.data.length>0 && data.data[0]!=null && data.data[0].guid != null && data.data[0].guid.length>0));
                    dispatch({ type: 'LISTS_RECEIVE', result: data.data });

                    if (appState.reminders.selectedList.guid == guid && data.data != null && data.data.length > 0 && data.data[0] != null && data.data[0].guid != null && data.data[0].guid.length > 0) {
                        //console.log("LISTS_SET_GUID");
                        dispatch({ type: 'LISTS_SET_GUID', guid: data.data[0].guid, name: data.data[0].name, linked: data.data[0].linked });
                    }
                });


            //console.log("c3");


        }
    },
    onListChange: (name: string): AppThunkAction<KnownFormsAction> => (dispatch, getState) => {
        const appState = getState();

        console.log("saveList()", appState);
        // Only load data if it's something we don't already have (and are not already loading)

        dispatch({ type: 'LIST_SAVE', name: name });


    },

    saveListFinal: (): AppThunkAction<KnownFormsAction> => (dispatch, getState) => {
        const appState = getState();

        console.log("saveList()", appState);
        // Only load data if it's something we don't already have (and are not already loading)

        if (appState && appState.reminders && appState.reminders.listIsLoading === false) {

            if (!(appState.reminders.selectedList.name != null && appState.reminders.selectedList.name.length > 0)) {

                appState.reminders.selectedList.name = "List Name";

            }

            //console.log("requestList() fetch");

            axios
                .get<ListType[]>(process.env.REACT_APP_API_ENDPOINT + '/todoapi/api.php?action=addUpdateList&user=' + appState.reminders.loginuser
                    + '&guid=' + appState.reminders.selectedList.guid
                    + '&name=' + appState.reminders.selectedList.name)
                //.then(response => response.json() as Promise<FormObject[]>)
                .then(data => {
                    //console.log("c3", data, appState.reminders.listguid, guid, (data.data!=null && data.data.length>0 && data.data[0]!=null && data.data[0].guid != null && data.data[0].guid.length>0));
                    //dispatch({ type: 'ITEMS_RECEIVE', result: data.data });

                    console.log("saveList 2");
                    dispatch({ type: 'LISTS_RECEIVE', result: data.data });
                });

            //console.log("c3");


        }

    },

    showHideList: (): AppThunkAction<KnownFormsAction> => (dispatch, getState) => {

        dispatch({ type: 'ON_SHOWHIDELIST' });

    },

    onListEdit: (): AppThunkAction<KnownFormsAction> => (dispatch, getState) => {

        dispatch({ type: 'LIST_ON_EDIT' });

    },


};



// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const unloadedState: TodoState = {
    itemsIsLoading: false, listIsLoading: false, remindersLoaded: false, listLoaded: false, loginuser: "", image: '', email: "",
    selectedList: { guid: "", name: "", linked: false },
    data: [],
    lists: [],
    //current: { id: 0, title: "", description: "", priority: 0, objectDate: new Date().toISOString().slice(0, 19).replace('T', ' ') },
    editList: false,
    showLists: false
};

export const reducer: Reducer<TodoState> = (state: TodoState | undefined, incomingAction: Action): TodoState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownFormsAction;

    console.log("todo", action);

    switch (action.type) {

        case 'LOGIN_USER':


            return {
                ...state,
                itemsIsLoading: false,
                listIsLoading: false,
                remindersLoaded: false,
                listLoaded: false,
                loginuser: action.loginuser,
                email: action.email,
                editList: false,
            };


        case 'LOGOUT_USER':
            return unloadedState;


        case 'ITEM_ON_EDIT':

            return {
                ...state,
                data: state.data.map(x => {
                    if (x.id == action.id) x.isEdit = true;
                    else x.isEdit = false;

                    return x;

                }),
                editList: false
            };


        case 'ITEM_ON_CHANGE':

            return {
                ...state,
                data: state.data.map(x => {
                    if (x.id == action.id) {
                        switch (action.name) {
                            case 'title': x.title = action.value; break;
                            case 'description': x.description = action.value; break;
                            case 'cron': x.cron = action.value; break;
                        }
                    }
                    return x;
                }),
                editList: false
            };

            break;

        case 'ON_SHOWHIDELIST':

            return { ...state, showLists: !state.showLists };


        case 'LIST_SAVE':
            return {
                ...state,
                //current: unloadedState.current,
                selectedList: { ...state.selectedList, name: action.name },
            };

        case 'LISTS_SET_GUID':
            return {
                ...state,
                data: unloadedState.data,
                itemsIsLoading: false,
                remindersLoaded: false,
                selectedList: { ...state.selectedList, guid: action.guid, name: action.name },
                showLists: false,
                editList: false
            };





        case 'LISTS_REQUEST':
            return {
                ...state,
                listIsLoading: true,
                listLoaded: false,
                //current: unloadedState.current,

                editList: false
            };
        case 'LISTS_RECEIVE':
            var resultlist = action.result.sort((a: ListType, b: ListType) => (a.name > b.name ? 1 : -1));

            return {
                ...state,
                listIsLoading: false,
                listLoaded: true,
                //current: unloadedState.current,

                lists: resultlist,
                editList: false
            };

        case 'ITEMS_REQUEST':
            return {
                ...state,
                itemsIsLoading: true,
                remindersLoaded: false,
                //current: unloadedState.current,

                editList: false
            };
        case 'ITEMS_RECEIVE':
            if (action.result != null && action.result.length > 0) {

                var maxIndex = action.result.reduce(
                    (max: number, x: { id: number; }) => (x.id > max ? x.id : max),
                    0
                );

                action.result = action.result.map((x: ItemType) => {
                    if (!(x.priority > 0)) x.priority = 0;
                    if (action.setEdit && x.id == maxIndex) x.isEdit = true;
                    if (!(x.cron != null && x.cron.length > 8)) x.cron = null;
                    return x;
                });


                action.result.sort((a: ItemType, b: ItemType) => (a.priority == b.priority) ? (a.objectDate < b.objectDate ? 1 : 0) : (a.priority < b.priority || b.priority == null ? -1 : 1));

                setTimeout(function () {
                    const t = document.getElementById('title_' + maxIndex);
                    if (t != null) t.focus();
                }, 300)
            }

            return {
                ...state,
                data: action.result,
                itemsIsLoading: false,
                remindersLoaded: true,
            };

        case 'LIST_ON_EDIT':

            return {
                ...state,
                itemsIsLoading: false,
                remindersLoaded: true,
                //current: unloadedState.current,

                editList: true
            };

        case 'IMAGE64':
            console.log("IMAGE64", action.image);


            return {
                ...state,
                image: action.image
            }

            break;
    }

    return state;
};
