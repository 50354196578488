import cronstrue from 'cronstrue'; //https://github.com/bradymholt/cronstrue

export interface ListType {
    //user: string;
    guid: string;
    name: string;
    linked: boolean;
}



export interface ItemType {
    id: number;
    priority: number;
    title: string,
    description: string;
    objectDate: string;
    email: string;
    cron: string | null;
    isEdit: boolean;
}
{/*
export interface FormValues {
    index: number;
    title: string;
    description: string;
    order: number;
}
 */}

export interface ILocalStorage {
    user: string;
    image: string;
    email: string;
    listguid: string;
    listname: string;
}

export interface ILink {
    user: string;
    guid: string;
}

export function cronDescription(value: string | null) {
    console.log("cronDescription", value);
    try {
        if (value != null && value.length > 0 && !(value.indexOf("?") >= 0)) {
            return cronstrue.toString(value, { verbose: true, throwExceptionOnParseError: false });
        }
    } catch (error) {
        console.error(error);
    }
    return "No alert";
}


export function replaceAll(str: string, find: string, replace: string) {
    return str.replace(new RegExp(find, 'g'), replace);
}